import debounce from 'lodash/debounce';
var FloatingHeader = /** @class */ (function () {
    function FloatingHeader(root) {
        this.root = root;
        this.documentContent = document.querySelector('.document-detail-toolbar');
        this.init();
    }
    FloatingHeader.prototype.init = function () {
        window.addEventListener('scroll', debounce(this.onScroll.bind(this), 50));
    };
    FloatingHeader.prototype.onScroll = function () {
        var contentTop = this.documentContent.getBoundingClientRect().top;
        if (contentTop <= -10) {
            this.root.classList.add('visible');
        }
        else {
            this.root.classList.remove('visible');
        }
    };
    return FloatingHeader;
}());
export default FloatingHeader;

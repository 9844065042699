var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var DocumentUploader = /** @class */ (function () {
    function DocumentUploader(root) {
        var _a;
        this.extractorUrl = root.dataset.extractorUrl || '';
        this.notices = document.createElement('div');
        this.fileUpload = document.getElementById('id_upload_file');
        if (this.fileUpload) {
            this.fileUpload.insertAdjacentElement('afterend', this.notices);
            (_a = this.fileUpload) === null || _a === void 0 ? void 0 : _a.addEventListener('change', this.onFileChanged.bind(this));
        }
    }
    DocumentUploader.prototype.onFileChanged = function (event) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var file, result, digest;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        file = (_a = this.fileUpload.files) === null || _a === void 0 ? void 0 : _a[0];
                        if (!file) return [3 /*break*/, 2];
                        this.notices.innerHTML = '';
                        result = document.createElement('div');
                        this.notices.appendChild(result);
                        return [4 /*yield*/, calculateDigest(file)];
                    case 1:
                        digest = _b.sent();
                        if (digest) {
                            // ask server if the file is a duplicate
                            // @ts-ignore
                            window.htmx.ajax('GET', "/admin/check-duplicate-file?sha256=".concat(digest), result);
                        }
                        if (this.extractorUrl) {
                            this.useExtractor(file);
                        }
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    DocumentUploader.prototype.useExtractor = function (file) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var status, csrfToken, formData, resp, html, parser, doc, result, _b, _c, e_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        status = document.createElement('div');
                        status.innerText = 'Extracting...';
                        this.notices.appendChild(status);
                        csrfToken = (_a = document.querySelector('input[name="csrfmiddlewaretoken"]')) === null || _a === void 0 ? void 0 : _a.getAttribute('value');
                        formData = new FormData();
                        formData.append('file', file);
                        formData.append('csrfmiddlewaretoken', csrfToken || '');
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 7, , 8]);
                        return [4 /*yield*/, fetch(this.extractorUrl, {
                                method: 'POST',
                                body: formData
                            })];
                    case 2:
                        resp = _d.sent();
                        if (!resp.ok) return [3 /*break*/, 4];
                        status.innerText = '';
                        return [4 /*yield*/, resp.text()];
                    case 3:
                        html = _d.sent();
                        parser = new DOMParser();
                        doc = parser.parseFromString(html, 'text/html');
                        this.replaceInputs(doc);
                        this.replaceFormsets(doc);
                        result = doc.querySelector('#extraction-result');
                        if (result) {
                            status.replaceWith(result);
                        }
                        return [3 /*break*/, 6];
                    case 4:
                        _b = status;
                        _c = 'Error: ';
                        return [4 /*yield*/, resp.text()];
                    case 5:
                        _b.innerText = _c + (_d.sent()) || resp.statusText;
                        _d.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        e_1 = _d.sent();
                        status.innerText = 'Error: ' + e_1;
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    DocumentUploader.prototype.replaceInputs = function (doc) {
        // insert the elements into the form
        doc.querySelectorAll('#inputs select[id], #inputs input[id]').forEach(function (input) {
            var _a;
            var el = document.getElementById(input.id);
            if (el) {
                // SELECT elements need special handling for select2
                if (el.tagName === 'SELECT' && ((_a = el.nextElementSibling) === null || _a === void 0 ? void 0 : _a.classList.contains('select2'))) {
                    el.nextElementSibling.remove();
                }
                el.replaceWith(input);
                if (el.tagName === 'SELECT') {
                    // @ts-ignore
                    window.django.jQuery(input).select2({ width: 'element' });
                }
            }
        });
    };
    DocumentUploader.prototype.replaceFormsets = function (doc) {
        doc.querySelectorAll('#formsets .inline-group').forEach(function (group) {
            // @ts-ignore
            var el = document.getElementById(group.id);
            if (el) {
                el.replaceWith(group);
                // trigger django's inline formset initialization
                var options = JSON.parse(el.dataset.inlineFormset || '{}');
                var selector = void 0;
                switch (el.dataset.inlineType) {
                    case 'stacked':
                        selector = options.name + '-group .inline-related';
                        // @ts-ignore
                        window.django.jQuery(selector).stackedFormset(selector, options.options);
                        break;
                    case 'tabular':
                        selector = options.name + '-group .tabular.inline-related tbody:first > tr.form-row';
                        // @ts-ignore
                        window.django.jQuery(selector).tabularFormset(selector, options.options);
                        break;
                }
            }
        });
    };
    return DocumentUploader;
}());
export default DocumentUploader;
/* Calculate SHA-256 hash of an ArrayBuffer */
function sha256(data) {
    return __awaiter(this, void 0, void 0, function () {
        var hashBuffer, hashArray;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, crypto.subtle.digest('SHA-256', data)];
                case 1:
                    hashBuffer = _a.sent();
                    hashArray = Array.from(new Uint8Array(hashBuffer));
                    return [2 /*return*/, hashArray.map(function (b) { return b.toString(16).padStart(2, '0'); }).join('')]; // convert bytes to hex string
            }
        });
    });
}
function calculateDigest(file) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    var reader = new FileReader();
                    reader.onload = function (event) {
                        var _a;
                        return __awaiter(this, void 0, void 0, function () {
                            var arrayBuffer, digest;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        arrayBuffer = (_a = event.target) === null || _a === void 0 ? void 0 : _a.result;
                                        return [4 /*yield*/, sha256(arrayBuffer)];
                                    case 1:
                                        digest = _b.sent();
                                        resolve(digest);
                                        return [2 /*return*/];
                                }
                            });
                        });
                    };
                    reader.onerror = function (event) {
                        reject(event);
                    };
                    reader.readAsArrayBuffer(file);
                })];
        });
    });
}

import AnnotationList from './AnnotationList.vue';
import { createAndMountApp } from '../../utils/vue-utils';
import { vueI18n } from '../../i18n';
import i18next from 'i18next';
var AnnotationsProvider = /** @class */ (function () {
    function AnnotationsProvider(root, manager, displayType) {
        this.root = root;
        this.manager = manager;
        this.gutter = root.querySelector('la-gutter');
        this.editable = this.root.hasAttribute('data-editable-annotation');
        // @ts-ignore
        this.listComponent = createAndMountApp({
            component: AnnotationList,
            props: {
                gutter: this.gutter,
                viewRoot: this.root,
                editable: this.editable
            },
            use: [vueI18n],
            mountTarget: document.createElement('div')
        });
        this.manager.addProvider(this);
    }
    AnnotationsProvider.prototype.getButton = function (target) {
        var btn = document.createElement('button');
        btn.className = 'btn btn-outline-secondary';
        btn.type = 'button';
        btn.innerText = i18next.t('Add comment...');
        return btn;
    };
    AnnotationsProvider.prototype.addEnrichment = function (target) {
        var _a;
        (_a = document.getSelection()) === null || _a === void 0 ? void 0 : _a.removeAllRanges();
        // @ts-ignore
        this.listComponent.addAnnotation(target);
    };
    return AnnotationsProvider;
}());
export { AnnotationsProvider };
